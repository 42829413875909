var category = [
	{
		id: 1,
		name: "Általános poszt",
		color: "#00a5eb",
		folder: "Altalanos",
	},
	{
		id: 2,
		name: "Adminisztratív, irodai diákmunkák",
		color: "#934f9a",
		folder: "Adminisztrativ",
	},
	{
		id: 3,
		name: "Áruházi, bolti, eladói diákmunkák",
		color: "#3a5ba7",
		folder: "Aruhazi_bolti_elado",
	},
	{
		id: 4,
		name: "Fizikai diákmunkák",
		color: "#e73629",
		folder: "Fizikai",
	},
	{
		id: 5,
		name: "Piackutatási, forgalomszámlálási diákmunkák",
		color: "#fed27a",
		folder: "Piackutatas_forgalomszamlalas",
	},
	{
		id: 6,
		name: "Informatikai, műszaki, mérnöki diákmunkák",
		color: "#56bcb7",
		folder: "Informatikus_muszaki_mernok",
	},
	{
		id: 7,
		name: "Promóciós, hostess diákmunkák",
		color: "#d31c5e",
		folder: "Promocios_hostess",
	},
	{
		id: 8,
		name: "Gyakornoki, szakmai munkák",
		color: "#71bc7a",
		folder: "Gyakornok_szakma",
	},
	{
		id: 9,
		name: "Mozis diákmunkák",
		color: "#f8ac00",
		folder: "Mozi",
	},
	{
		id: 10,
		name: "Idegennyelvű diákmunkák",
		color: "#7e722d",
		folder: "Idegennyelvu",
	},
	{
		id: 11,
		name: "Telefonos diákmunka",
		color: "#7cbb4d",
		folder: "Telefonos",
	},
	{
		id: 12,
		name: "Vendéglátás, idegenforgalmi, gyorséttermi diákmunka",
		color: "#ec6052",
		folder: "Vendeglatas_idegenforgalom_gyorsetterem",
	},
	{
		id: 13,
		name: "Egyéb diákmunkák",
		color: "#c8af25",
		folder: "Egyeb",
	},
	{
		id: 14,
		name: "Kategória nélkül",
		color: "#ffffff00",
		folder: "kategoria_nelkul",
	},
];

var mdColorBlue = "#00a5eb",
	_canvas = {
		width: 1250,
		height: 694,
	},
	_mainBox = {
		top: 480,
		left: 50,
		right: 150,
		height: 88,
	},
	_textFocim = {
		top: 498,
		left: 61,
		height: 56.5,
		fontsize: 50,
		fontweight: 300,
		fontfamily: "CamptonBold",
	},
	_textAlcim = {
		top: 585,
		left: 57,
		fontsize: 29,
		fontfamily: "Open Sans",
		fontweight: 300,
	},
	backgroundAdded = false;

var canvas = new fabric.Canvas("mdCanvas", {
	backgroundColor: "#bbb",
	width: _canvas.width,
	height: _canvas.height,
	containerClassName: "mdCanvas",
	preserveObjectStacking: true,
	renderOnAddRemove: true,
	enableRetinaScaling: false,
	controlsAboveOverlay: true,
	selection: false,
});

//  Főcím
var textFocim = new fabric.IText("FŐCÍM...", {
	left: _textFocim.left,
	top: _textFocim.top,
	// textBackgroundColor: "red",
	fontSize: _textFocim.fontsize,
	fontFamily: _textFocim.fontfamily,
	lineHeight: 1.2,
	fill: "#ffffff",
	fontWeight: _textFocim.fontweight,
	textAlign: "left",
	selectable: false,
	editable: false,
	hasControls: false,
});

//  Alcím
var textAlcim = new fabric.IText("Alcím...", {
	left: _textAlcim.left,
	top: _textAlcim.top,
	fontSize: _textAlcim.fontsize,
	fontFamily: _textAlcim.fontfamily,
	lineHeight: 1.2,
	fill: "#ffffff",
	fontWeight: _textAlcim.fontweight,
	textAlign: "left",
	selectable: false,
	editable: false,
	hasControls: false,
});

//  Szövegbox
var mainBoxRect = new fabric.Rect({
	left: _mainBox.left,
	top: _mainBox.top,
	fill: mdColorBlue,
	height: _mainBox.height,
	width: 227,
	opacity: 1,
	selectable: false,
	editable: false,
	hasControls: false,
});

$(document).on("click", "input[type=text]", function () {
	this.select();
});

//KÉP MENTÉS
$("button#savetogallery").click((event) => {
	event.preventDefault();
	canvas.discardActiveObject().renderAll();

	var d = new Date(),
		n = d.getTime(),
		fileName =
			"melodiak-" + n + "-" + _canvas.width + "x" + _canvas.height + "px";

	// letöltés
	canvasToImage("mdCanvas", {
		name: fileName,
		type: "jpg",
		quality: 1,
	});
});

$("button#loadImage").click(() => {
	scrollToSection("#galleryTitle");
});

function setAlcimTextColor(c) {
	var a = c.replace("#", "");
	$("button#colorPicker").css({ backgroundColor: c });

	$(".colorswatch").removeClass("current");
	$("#c" + a).addClass("current");

	textAlcim.set({
		fill: c,
	});
	canvas.renderAll();
}

var alcimTextColorPal = [
	["#ffffff", "Fehér"],
	["#000000", "Fekete"],
];

var colorTemplate =
	'<div data-col="{color}" style="background-color: {color}; color: {textcolor}" class="colorswatch" id="c{attr}"></div>';
var colorPalHtml = "<span>";
alcimTextColorPal.forEach(function (c, i) {
	var a = c[0].replace("#", "");
	var bgcolor = tinycolor(c[0]);
	var textcolor = bgcolor.toName() == "white" ? "black" : "white";
	colorPalHtml += colorTemplate
		.replace(/\{color\}/g, c[0])
		.replace(/\{kat\}/g, c[1])
		.replace(/\{textcolor\}/g, textcolor)
		.replace(/\{attr\}/g, a);
	if ((i + 1) % 1 == 0) {
		colorPalHtml += "</span>";
		colorPalHtml += "<span>";
	}
});
colorPalHtml += "</span>";
$("#color-picker-container").html(colorPalHtml);

$("button#colorPicker").click(() => {
	$("#color-picker-container").fadeIn(100);
});

$("#color-picker-container").mouseleave(() => {
	$("#color-picker-container").fadeOut(100);
});

$(".colorswatch").click(({ currentTarget }) => {
	setAlcimTextColor($(currentTarget).data("col"));
});

// ABLAK
$("#overlay").hide();
$("#loaderWindow").hide();

function showLoaderWindow(overlay = false) {
	$("#loaderWindow").show();
	if (overlay) {
		$("#overlay").css("background-color", "rgba(white, 0.8)").fadeIn(100);
	} else {
		$("#overlay").css("background-color", "rgba(white, 0)").fadeIn(100);
	}
}

function hideLoaderWindow() {
	$("#overlay").fadeOut(100).css("background-color", "rgba(white, 0)");
	$("#loaderWindow").hide();
}

// Smooth scroll
function scrollToSection(section) {
	$("html, body").animate(
		{
			scrollTop: $(section).offset().top - 100,
		},
		500
	);
}

// GALÉRIA

var filterCategory = "0";

function searchCategory(kategoriaID) {
	$(".nincseredmeny").hide();
	if (filterCategory == "0") {
		$(".galleryItem").removeClass("blurred");
	} else {
		$(".galleryItem").addClass("blurred");
		$("[data-category='" + filterCategory + "']").removeClass("blurred");
	}
	var keresesiTalalatok = $(".galleryItem").not(".blurred").length;
	if (keresesiTalalatok == 0) {
		$(".nincseredmeny").show();
	}
	// console.log("Találatok: ", keresesiTalalatok);
	$(".talalatok span").html(keresesiTalalatok);
}

$("#focimText").on("keyup keydown change", ({ currentTarget }) => {
	var focimText = $(currentTarget).val(),
		padding = 15,
		maxWidth = _canvas.width - (_mainBox.left + _mainBox.right),
		maxTextWidth = maxWidth - padding * 2,
		mainBoxWidth,
		textBoxWidth,
		fontSize = _textFocim.fontsize;

	textFocim.set({ text: focimText.toUpperCase(), fontSize: fontSize });
	canvas.renderAll();
	textBoxWidth = textFocim.getBoundingRect().width;

	while (textBoxWidth > maxTextWidth) {
		fontSize--;
		textFocim.set({
			text: focimText.toUpperCase(),
			fontSize: fontSize,
			top:
				_mainBox.top +
				(_mainBox.height - textFocim.getBoundingRect().height + 8) / 2,
		});
		canvas.renderAll();
		textBoxWidth = textFocim.getBoundingRect().width;
	}

	mainBoxWidth = textBoxWidth + padding * 2;
	if (mainBoxWidth > maxWidth) mainBoxWidth = maxWidth;
	mainBoxRect.set({
		width: mainBoxWidth,
	});
	canvas.renderAll();
});

$("#alcimText").on("keyup keydown change", ({ currentTarget }) => {
	var alcimText = $(currentTarget).val(),
		padding = 15,
		maxWidth = _canvas.width - (_mainBox.left + _mainBox.right),
		maxTextWidth = maxWidth - padding * 2,
		textBoxWidth,
		fontSize = _textAlcim.fontsize;

	textAlcim.set({ text: alcimText, fontSize: fontSize });
	canvas.renderAll();
	textBoxWidth = textAlcim.getBoundingRect().width;

	while (textBoxWidth > maxTextWidth) {
		fontSize--;
		textAlcim.set({
			text: alcimText,
			fontSize: fontSize,
		});
		canvas.renderAll();
		textBoxWidth = textAlcim.getBoundingRect().width;
	}
});

var bothselects = $("#kategoria, #filterCategory");
bothselects.on("change", function ({ currentTarget }) {
	bothselects.val(this.value);

	filterCategory = $("option:selected", currentTarget).val();
	searchCategory(filterCategory);

	canvas.getObjects().map(function (o) {
		if (o.type == "rect") {
			o.set("fill", $("option:selected", currentTarget).data("color"));
		}
	});

	// mainBox.set("fill", categoryColor);
	canvas.renderAll();
});

// ----------------------------------------------------------------------------------------------
$(document).ready(() => {
	$("#color-picker-container").hide();
	showLoaderWindow(true);

	var el = $("#kategoria, #filterCategory");
	el.empty();

	category.forEach(function (e, i) {
		el.append(
			$("<option></option>")
				.attr("value", e.id)
				.attr("data-color", e.color)
				.text(e.name)
		);
	});

	var categoryString = JSON.stringify(category);
	$.ajax({
		method: "POST",
		url: "loadGallery.php",
		data: { category: categoryString.slice(2, -2) },
		cache: false,
		success: function (data) {
			$("#gallery").html(data);

			$(".loadImageToCanvas").click(({ currentTarget }) => {
				var newBgImage;

				if (canvas.item(0).type == "image") {
					canvas.item(0).remove();
				}

				newBgImage = fabric.Image.fromURL(
					$(currentTarget).data("image"),
					function (img) {
						img.scale(1.0)
							.set({
								left: 0,
								top: 0,
								hascontrols: false,
								selectable: false,
							})
							.setControlsVisibility({
								mt: false,
								mb: false,
								ml: false,
								mr: false,
							});
						canvas.add(img).sendToBack(img);
					}
				);
				scrollToSection("#editor");
			});
		},
		error: function (req, status, err) {
			console.log("hiba: ", status, err);
		},
	});

	var fonts = ["Open Sans", "CamptonBold"];
	Promise.all(
		// Preload fonts
		fonts.map((font) => new FontFaceObserver(font).load())
	).then(() => {
		// Load page elements
		setAlcimTextColor("#ffffff");

		canvas.add(mainBoxRect).add(textFocim).add(textAlcim).renderAll();

		hideLoaderWindow(true);

		filterCategory = $("option:selected", "#kategoria").val();
		searchCategory(filterCategory);

		$(".talalatok span").html($(".galleryItem").not(".blurred").length);
	});
});
